import React from "react"
import styled from "styled-components"

import Paragraph from "../../atoms/Paragraph/Paragraph"
import { Span } from "../../atoms/Span/Span"


const TextWrapper = styled.div`
    text-align: ${({ reversed }) => reversed ? "right" : "left"};
`

const StyledTitle = styled.h2`
    font-size: 2.4rem;
    font-family: ${({theme}) => theme.font.family.greta};
    margin-top: .8rem;

    ${({theme}) => theme.mq.desktop} {
        font-size: 3.2rem;
    }

    ${({ theme }) => theme.mq.widescreen} {
        font-size: 4rem;
    }

    ${({ theme }) => theme.mq.fullhd} {
        font-size: ${({theme}) => theme.font.size.xl}
    }
`

const StyledParagraph = styled(Paragraph)`
    margin: 16px 0 32px 0;
`

const TextStack = ({span, title, paragraph, ...props}) => (
    <TextWrapper {...props}>
        <Span>{span}</Span>
        <StyledTitle>{title}</StyledTitle>
        <StyledParagraph>{paragraph}</StyledParagraph>
    </TextWrapper>
)

export default TextStack