import styled, { css } from "styled-components";
import AniLink from "gatsby-plugin-transition-link/AniLink"
import Paragraph from "../../atoms/Paragraph/Paragraph"
import { Heading } from "../../atoms/Heading/Heading"
import Img from "gatsby-image"

export const StyledWrapper = styled.div`
    width: 100vw;
    height: 100vh;
    position: relative;

    ${({ subpage }) => subpage && css`
        ${({ theme }) => theme.mq.desktop} {
            height: 80vh;
        }
    `}
`

export const StyledHeroContent = styled.div`
    text-align: center;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;

    ${({theme}) => theme.mq.desktop} {
        text-align: left;
        align-items: flex-start;
        width: ${({ subpage }) => subpage ? "800px" : "600px"};
    }
`

export const StyledHeading = styled(Heading)`
    ${({ subpage }) => subpage && css`
        ${({ theme }) => theme.mq.widescreen} {
            font-size: 6.4rem;
            line-height: 80px;
        }
    `}
`

export const StyledParagraph = styled(Paragraph)`
    margin: 16px 0 24px;
    width: 70%;
    color: #fff;
    font-size: ${({theme}) => theme.font.size.l};
`

export const BackgroundImage = styled(Img)`
    position: absolute!important;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    object-fit: cover!important;
`

export const HeroContent = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 250px);

    ${({ theme }) => theme.mq.desktop} {
        width: 90%;
        margin: 0 auto;
        justify-content: flex-start;
        height: ${({ subpage }) => subpage ? "calc(80vh - 200px)" : "calc(100vh - 200px)"};
    }

    ${({ theme }) => theme.mq.widescreen} {
        width: 85%;
    }

    ${({ theme }) => theme.mq.fullhd} {
        width: 80%;
    }
`

export const ButtonLink = styled(AniLink)`
    border: 2px solid ${({theme}) => theme.color.blue};
    background-color: ${({theme}) => theme.color.blue};
    color: ${({theme}) => theme.color.white};
    text-transform: uppercase;
    font-family: ${({ theme }) => theme.font.family.avenir};
    font-weight: bold;
    letter-spacing: 1.6px;
    padding: 13px 22px;
    text-decoration: none;
    font-size: 1.3rem;

    ${({ theme }) => theme.mq.desktop} {
        padding: 18px 25px;
    }

    ${({ theme }) => theme.mq.widescreen} {
        padding: 18px 35px;
    }
`

export const FacebookButton = styled.div`
    position: fixed;
    right: 0;
    top: 25%;
    z-index: 1000000000;
    transform: translateX(300px);
    display: flex;
    transition: all 0.4s ease-in-out;
    cursor: pointer;

    &:hover {
        transform: translateX(0px);
    }
`

export const FacebookIcon = styled.img`
    height: 45px;
    position: absolute;
    left: -45px;
`