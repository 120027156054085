import React from "react"
import { Heading } from "../../atoms/Heading/Heading"
import facebookButton from "../../../images/facebookbutton.svg"
import Header from "../Header/Header"

import { StyledWrapper, BackgroundImage, HeroContent, StyledHeroContent, StyledHeading, StyledParagraph, ButtonLink, FacebookButton, FacebookIcon } from "./Hero.styles"

const Hero = ({ title, claim, subpage, images }) => (
    <StyledWrapper subpage={subpage}>
            <BackgroundImage loading="eager" fluid={images} alt="Hotel Collis logo" />
            <Header />
            <HeroContent subpage={subpage}>
                <StyledHeroContent subpage={subpage}>
                    <StyledHeading subpage={subpage}>{title}</StyledHeading>
                    {claim && <StyledParagraph>{claim}</StyledParagraph>}
                    {!subpage && <ButtonLink cover="true"
                        to="/o-inwestycji"
                        direction="left"
                        duration={1}
                        color="#212548">
                        Czytaj Dalej
                    </ButtonLink>}
                </StyledHeroContent>
            </HeroContent>
            <FacebookButton><FacebookIcon src={facebookButton} /><iframe title="facebook" src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2FHotel.Collis.Investment%2F&tabs=timeline&width=300&height=450&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId=1362498703933293" width="300" height="450" style={{border: "none", overflow:"hidden"}} scrolling="no" frameBorder="0" allow="encrypted-media"></iframe></FacebookButton>
        </StyledWrapper>
)

export default Hero