import React from "react"
import styled from "styled-components"

const StyledHamburger = styled.button`
    padding: 15px;
    position: relative;
    right: -15px;
    border: 0;
    background: none;
    z-index: 10000;
`

const InnerHamburger = styled.div`
    position: relative;
    width: 24px;
    height: 2px;
    top: ${({ open }) => open ? "0" : "-3px"};
    background-color: #fff;
    transform: ${({ open }) => open ? "rotate(-45deg)" : "rotate(0)"};
    transition: all 0.3s ease;

    ::after {
        content: '';
        background-color: #fff;
        position: absolute;
        transition: all 0.3s ease;

        width: ${({ open }) => open ? "24px" : "18px"};
        transform: ${({ open }) => open ? "rotate(90deg)" : "rotate(0)"};
        height: 2px;
        right: 0;
        top: ${({ open }) => open ? "0" : "7px"};
    }
`

const Hamburger = ({open, setOpen}) => (
    <StyledHamburger open={open} onClick={setOpen}>
        <InnerHamburger open={open} />
    </StyledHamburger>
)

export default Hamburger