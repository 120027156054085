import styled from "styled-components"

export const Heading = styled.h1`
    font-size: ${({theme}) => theme.font.size.xl};
    line-height: 60px;

    ${({ theme }) => theme.mq.desktop} {
        font-size: 6.4rem;
        line-height: 80px;
    }

    ${({theme}) => theme.mq.widescreen} {
        font-size: 7.2rem;
        line-height: 90px;
    }  

    ${({ theme }) => theme.mq.fullhd} {
        font-size: ${({theme}) => theme.font.size.xxl};
        line-height: 100px;
    }
`