import React from "react"
import styled from "styled-components"
import facebookIcon from "../../../images/facebook.svg"
import linkedinIcon from "../../../images/linkedin.svg"

const StyledWrapper = styled.nav`

`

const MenuList = styled.ul`
    display: flex;
    list-style-type: none;
`

const MenuListItem = styled.li`
    padding: 10px;
`

const MenuLink = styled.a`
    display: flex;
    align-content: center;
`

const SocialIcon = styled.img`
    max-height: 20px;
`

const SocialMenu = () => (
    <StyledWrapper>
        <MenuList>
            <MenuListItem><MenuLink href="https://www.facebook.com/Hotel.Collis.Investment" target="_blank" rel="noopener noreferrer" aria-label="Facebook"><SocialIcon src={facebookIcon} alt="facebook icon"/></MenuLink></MenuListItem>
            <MenuListItem><MenuLink href="https://www.linkedin.com/company/hotel-collis/" target="_blank" rel="noopener noreferrer" aria-label="LinkedIn"><SocialIcon src={linkedinIcon} alt="linkedin icon"/></MenuLink></MenuListItem>
        </MenuList>
    </StyledWrapper>
)

export default SocialMenu