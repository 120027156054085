import styled from "styled-components"
import Img from "gatsby-image"
import AniLink from "gatsby-plugin-transition-link/AniLink"

export const StyledWrapper = styled.div`
    position: relative;
    margin-top: 100px;

    ${({ theme }) => theme.mq.desktop} {
        margin-top: 0;
        height: 450px;
        display: flex;
        align-items: flex-end;
    }

    ${({ theme }) => theme.mq.widescreen} {
        height: 800px;
    }
`

export const BackgroundImage = styled(Img)`
    display: none;

    ${({ theme }) => theme.mq.desktop} {
        display: block;
        position: absolute!important;
        bottom: 0;
        left: 0;
        width: 100%;
        z-index: -1;
    }
`

export const InnerWrapper = styled.div`
    width: 100%;
    background: ${({ theme }) => theme.color.blue};

    ${({ theme }) => theme.mq.desktop} {
        width: 100%;
        background: linear-gradient(0deg, rgba(0,0,0,.8) 0%, rgba(0,0,0,0) 100%);
        max-height: 352px;
    }
`

export const StyledFooter = styled.footer`
    padding: 64px 32px;
    width: 100%;
    color: white;
    display: flex;
    flex-direction: column;

    ${({ theme }) => theme.mq.desktop} {
        padding: 64px 0;
        width: 90%;
        margin: 0 auto;
        max-height: 352px;
        background-color: transparent;
        flex-direction: row;
        justify-content: space-between;
    }

    ${({ theme }) => theme.mq.widescreen} {
        width: 80%;
    }

`

export const Logo = styled.img`
    width: 100px;
`

export const StyledFooterItem = styled.div`
    margin-top: 3.2rem;
`

export const StyledHeading = styled.h3`
    margin-bottom: 1.6rem;
`

export const StyledList = styled.ul`
    display: flex;
    flex-direction: column;
`

export const StyledListItem = styled.li`
    margin-bottom: .8rem;
    list-style: none;
`

export const StyledLink = styled(AniLink)`
    text-decoration: none;
    color: ${({ theme }) => theme.color.white};
    opacity: 0.6;
    transition: ease-in-out 200ms;

    &:hover {
        opacity: 1;
    }
`