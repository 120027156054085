import React from "react"
import { ThemeProvider } from "styled-components"
import GlobalStyle from "../../assets/styles/globalStyles"
import { theme } from "../../assets/styles/theme"
import { Helmet } from "react-helmet"
import SEO from "../../components/seo"

const MainTemplate = ({ children }) => {
  return (
    <>
      <GlobalStyle />
      <SEO title="Hotel Collis" />
      <Helmet></Helmet>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </>
  )
}

export default MainTemplate
