import React from "react"
import styled, { keyframes } from "styled-components"
import AniLink from "gatsby-plugin-transition-link/AniLink"

const StyledWrapper = styled.nav`
    display: none;

    ${({ theme }) => theme.mq.desktop} {
        display: block;
    }
`

const StyledList = styled.ul`
    list-style-type: none;
    display: flex;
`

const StyledListItem = styled.li`
    padding: 0 7px;
    text-align: center;

    ${({theme}) => theme.mq.widescreen} {
        margin: 0 20px;
        padding: 0;
        position: relative;
    }

    ${({ theme }) => theme.mq.fullhd} {
        margin: 0 30px;
    }
`

const navHover = keyframes`
    from {
      left: 50%;
      width: 0%; 
    }
    to {
      left: 0%;
      width: 100%; 
    }
`

const StyledLink = styled(AniLink)`
    text-decoration: none;
    font-weight: bold;
    letter-spacing: 1.6px;
    color: ${({ theme }) => theme.color.white};
    transition: all 0.5s ease;

    &:hover::after {
        content: '';
        position: absolute;
        width: 100%;
        bottom: -10px;
        background-color: #fff;
        height: 1px;
        animation: ${navHover} .3s ease forwards;
    }
`

const StyledContactLink = styled.a`
    text-decoration: none;
    font-weight: bold;
    letter-spacing: 1.6px;
    color: ${({ theme }) => theme.color.white};
    transition: all 0.5s ease;

    &:hover::after {
        content: '';
        position: absolute;
        width: 100%;
        bottom: -10px;
        background-color: #fff;
        height: 1px;
        animation: ${navHover} .3s ease forwards;
    }
`

const menuItems = [
    {title: "O inwestycji", page: "/o-inwestycji"},
    {title: "Lokalizacja", page: "/lokalizacja"},
    {title: "Obiekt istniejący", page: "/villa-collis"},
    {title: "Park Ochrony Bieszczadzkiej Fauny", page: "/park-ochrony-bieszczadzkiej-fauny"},
]

const Navigation = () => (
    <StyledWrapper>
        <StyledList>
            {menuItems.map((item, index) => (
                <StyledListItem key={index}><StyledLink cover to={item.page} direction="left" duration={1} bg="#212548">{item.title}</StyledLink></StyledListItem>
            ))}
            <StyledListItem><StyledContactLink href="/#contact">Kontakt</StyledContactLink></StyledListItem>
        </StyledList>
    </StyledWrapper>
)

export default Navigation