import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import logo from "../../../images/logo-white.svg"
import SocialMenu from "../../molecules/SocialMenu/SocialMenu"

import { StyledWrapper, BackgroundImage, InnerWrapper, StyledFooter, Logo, StyledFooterItem, StyledHeading, StyledList, StyledListItem, StyledLink } from "./Footer.styles"

const menuItems = [
    {title: "O inwestycji", page: "/o-inwestycji"},
    {title: "Lokalizacja", page: "/lokalizacja"},
    {title: "Obiekt istniejący", page: "/villa-collis"},
    {title: "Park Ochrony Bieszczadzkiej Fauny", page: "/park-ochrony-bieszczadzkiej-fauny"},
    {title: "Kontakt", page: "/#contact"}
]

const infoItems = [
    {title: "Park Ochrony Bieszczadzkiej Fauny", page: "/park-ochrony-bieszczadzkiej-fauny"},
    {title: "Inwestycje w okolicy", page: "/lokalizacja"},
    {title: "Mapa strony", page: "/"},
]

const Footer = () => {
    const data = useStaticQuery(query)

    return (
        <StyledWrapper>
            <BackgroundImage fluid={data.file.childImageSharp.fluid} alt="" />
            <InnerWrapper>
            <StyledFooter>
        <Logo src={logo} />
        <StyledFooterItem>
            <StyledHeading>O nas</StyledHeading>
            <StyledList>
                {menuItems.map((item, index) => (
                    <StyledListItem key={index}><StyledLink fade to={item.page}>{item.title}</StyledLink></StyledListItem>
                ))}
            </StyledList>
        </StyledFooterItem>
        <StyledFooterItem>
            <StyledHeading>Informacje</StyledHeading>
            <StyledList>
                {infoItems.map((item, index) => (
                    <StyledListItem key={index}><StyledLink fade to={item.page}>{item.title}</StyledLink></StyledListItem>
                ))}
            </StyledList>
        </StyledFooterItem>
        <StyledFooterItem>
            <StyledHeading>Znajdź nas na social media
            </StyledHeading>
            <SocialMenu />
        </StyledFooterItem>
    </StyledFooter>
            </InnerWrapper>
        </StyledWrapper>
    )
}

export const query = graphql`
    {
        file(name: {eq: "footer-image1"}) {
            childImageSharp {
                fluid (maxWidth: 1920, maxHeight: 1000, quality: 100){
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                }
            }
        }
    }
`

export default Footer