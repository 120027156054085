import React, { useState } from "react"
import styled from "styled-components"
import AniLink from "gatsby-plugin-transition-link/AniLink"

import Navigation from "../../molecules/Navigation/Navigation"
import MobileMenu from "../..//molecules/MobileMenu/MobileMenu"
import logo from "../../../images/logo-white.svg"
import Hamburger from "../../atoms/Hamburger/Hamburger"

const StyledWrapper = styled.header`
    padding: 16px 32px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    ${({ theme }) => theme.mq.desktop} {
        padding: 16px 0;
        width: 90%;
        margin: 0 auto;
    }

    ${({ theme }) => theme.mq.widescreen} {
        width: 85%;
    }

    ${({ theme }) => theme.mq.fullhd} {
        width: 80%;
    }
`

const StyledLink = styled(AniLink)`
    z-index: 100;
`

const StyledLogo = styled.img`
    width: 100px;
`


const MobileNavigation = styled.div`

    ${({ theme }) => theme.mq.desktop} {
        display: none
    }
`

const Header = () => {
    const [open, setOpen] = useState(false)

    const handleSetOpen = () => {
        setOpen(!open)
        document.body.setAttribute("style", open ? "" : "overflow: hidden")
    }
    return (
        <StyledWrapper>
            <StyledLink cover
            to="/"
            direction="left"
            duration={1}
            bg="#212548">
                <StyledLogo src={logo} />
            </StyledLink>
            <Navigation />
            <MobileNavigation>
                <Hamburger open={open} setOpen={handleSetOpen} />
                <MobileMenu open={open} setOpen={handleSetOpen} />
            </MobileNavigation>
        </StyledWrapper>
    )
}

export default Header