export const theme = {
    color: {
        black: "#000",
        white: "#FFF",
        grey: "#414141",
        blue: "#232449",
    },
    font: {
        size: {
            s: "1.4rem",
            m: "1.6rem",
            l: "2rem",
            xl: "4.8rem",
            xxl: "8rem",
        },
        family: {
            avenir: `"Avenir", sans-serif`,
            greta: `"Montserrat", serif`
        }
    },
    mq: {
        tablet: "@media (min-width: 768px)",
        desktop: "@media (min-width: 1024px)",
        widescreen: "@media (min-width: 1215px)",
        fullhd: "@media (min-width: 1441px)",
    }
    
}