import React from "react"
import styled from "styled-components"
import AniLink from "gatsby-plugin-transition-link/AniLink"


const StyledWrapper = styled.nav`
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: ${({theme}) => theme.color.blue};
    display: flex;
    align-items: center;
    justify-content: center;
    transform: ${({ open }) => open ? "translateY(0)" : "translateY(-100%)"};
`

const StyledList = styled.ul`
    position: relative;
    list-style-type: none;
    width: 100%;
    top: 10%;
`

const StyledListItem = styled.li`
    text-align: center;
    margin: 5rem 0;
    width: 100%;
`

const StyledLink = styled(AniLink)`
    width: 100%;
    padding: 20px 0;
    text-decoration: none;
    font-weight: bold;
    color: ${({theme}) => theme.color.white};
`

const menuItems = [
    {title: "O inwestycji", page: "/o-inwestycji"},
    {title: "Lokalizacja", page: "/lokalizacja"},
    {title: "Obiekt istniejący", page: "/villa-collis"},
    {title: "Park Ochrony Bieszczadzkiej Fauny", page: "/park-ochrony-bieszczadzkiej-fauny"},
    {title: "Kontakt", page: "/#contact"}
]

const MobileMenu = ({ open, setOpen}) => (
    <StyledWrapper open={open}>
        <StyledList>
            {menuItems.map((item, index) => (
                <StyledListItem onClick={() => document.body.setAttribute("style", "")}key={index}><StyledLink fade to={item.page}>{item.title}</StyledLink></StyledListItem>
            ))}
        </StyledList>
    </StyledWrapper>
)

export default MobileMenu